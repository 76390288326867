// import { lazy, Suspense, useEffect, useState } from "react";
// import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import ProtectedRoutes from "./routes/ProtectedRoutes";
// import ReactDOM from "react-dom";
// import { BrowserRouter as Router } from "react-router-dom";
// import "./index.css";
// // const LoginPage = lazy(() => import("./features/OnBoarding/LoginPage"));
// // const Dashboard = lazy(() => import("./features/Dashboard/Dashboard"));
// // const Patients = lazy(() => import("./features/Patients/Patients"));
// // const Viewpatient = lazy(() => import("./features/Patients/Viewpatient"));
// // const Clients = lazy(() => import("./features/Clients/Clients"));
// // const ViewClient = lazy(() => import("./features/Clients/ViewClient"));
// //const Quotes = lazy(() => import("./features/Quotes/Quotes"));
// const Quotes = lazy(() => import("../src/features/CreateQuote/SalesQuotes"));
// function App() {
//   const dispatch = useDispatch();
//   //Getting isAuthenticated store value from Authentication slice.
//   const [isAuthenticated, setIsAuthenticated] = useState(true);
//   // const isAuthenticatedState = useSelector(
//   //   (state) => state.authentication.isAuthenticated
//   // );
//   // useEffect(() => {
//   //   if (!isAuthenticatedState) {
//   //     var isAuthenticateStorage = localStorage.getItem("isAuthenticated");
//   //     var token_bearer = localStorage.getItem("token_bearer");
//   //     setIsAuthenticated(isAuthenticateStorage);
//   //     if (isAuthenticateStorage && token_bearer) {
//   //       dispatch(loginSessionAvailable());
//   //     } else {
//   //       setIsAuthenticated(false);
//   //     }
//   //   } else {
//   //     setIsAuthenticated(isAuthenticatedState);
//   //   }
//   // }, [isAuthenticatedState, dispatch]);

//   return (
//     <>
//       {/* <LoginPage /> */}
//       <BrowserRouter>
//         <ProtectedRoutes />
//         <Routes>
//           <Route path="/quotes" element={<Quotes />} />
//         </Routes>
//         {/* <Routes>
//           <Route path="/login" element={<LoginPage />} />
//           <Route path="/menu" element={<ProtectedRoutes />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/patients" element={<Patients />} />
//           <Route path="/viewpatient" element={<Viewpatient />} />
//           <Route path="/clients" element={<Clients />} />
//           <Route path="/viewclient" element={<ViewClient />} />
//         </Routes> */}
//       </BrowserRouter>
//     </>
//   );
// }

// export default App;

import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Login from "../src/features/OnBoarding/LoginPage";
import "./index.css";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { loginSessionAvailable } from "./features/OnBoarding/authenticationSlice";
// Lazy load the Quotes component
const Quotes = lazy(() => import("../src/features/CreateQuote/SalesQuotes"));
const CreateQuote = lazy(() =>
  import("../src/features/CreateQuote/Components/CreateQuote")
);
const Settings = lazy(() => import("../src/features/Settings/Settings"));
const LoginPage = lazy(() => import("../src/features/OnBoarding/LoginPage"));

function App() {
  // Initialize state for locale and messages
  const dispatch = useDispatch();
  const [locale, setLocale] = useState("en-US");
  const [messages, setMessages] = useState({});
  //const isAuthenticated = false;

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const isAuthenticatedState = useSelector(
    (state) => state?.authentication?.isAuthenticated
  );
  useEffect(() => {
    if (!isAuthenticatedState) {
      var isAuthenticateStorage = localStorage.getItem("isAuthenticated");
      var token_bearer = localStorage.getItem("token_bearer");
      setIsAuthenticated(isAuthenticateStorage);
      if (isAuthenticateStorage && token_bearer) {
        dispatch(loginSessionAvailable());
      } else {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(isAuthenticatedState);
    }
  }, [isAuthenticatedState, dispatch]);
  console.log("isAuthenticated", isAuthenticated);
  return (
    <BrowserRouter>
      {isAuthenticated == true ? (
        <ProtectedRoutes />
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Login />
          {/* <Routes>
            <Route path="/login" element={<LoginPage />} />
          </Routes> */}
        </Suspense>
      )}
    </BrowserRouter>
  );
}

export default App;
