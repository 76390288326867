import { Alert, Spin, Switch } from "antd";
import React from "react";
import { useSelector } from "react-redux";

function GlobalSpinner(props) {
  const { loader, tip = "" } = useSelector((state) => state.GlobalSpinner);
  return (
    <Spin spinning={loader} tip={tip} size="large">
      {props.appContainer}
    </Spin>
  );
}

export default GlobalSpinner;
