import { lazy } from "react";

const routes = [
  // {
  //   path: "dashboard",
  //   component: lazy(() => import("../features/Dashboard/Dashboard")),
  //   exact: true,
  // },
  {
    path: "quotes",
    component: lazy(() => import("../features/CreateQuote/SalesQuotes")),
    exact: true,
  },
  {
    path: "settings/CategoryActivities/:id",
    component: lazy(() =>
      import("../features/Settings/Activities/Components/SstInActivities")
    ),
    exact: true,
  },
  {
    path: "createquote/:step/:quoteId?",
    component: lazy(() =>
      import("../features/CreateQuote/Components/CreateQuote")
    ),
    exact: true,
  },
  {
    path: "settings",
    component: lazy(() => import("../features/Settings/Settings")),
    exact: true,
  },
  {
    path: "estimate/:quoteId?",
    component: lazy(() =>
      import("../features/CreateQuote/Components/Estimate")
    ),
    exact: true,
  },
];

export default routes;
