import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import authentication from "../features/OnBoarding/authenticationSlice";
import Suppliers from "../features/Settings/Suppliers/SuppliersSlice";
import Categories from "../features/Settings/Suppliers/SuppliersSlice";
import Materials from "../features/Settings/Suppliers/SuppliersSlice";
import SubContractors from "../features/Settings/SubContractors/SubContractorsSlice";
import Equipments from "../features/Settings/Equipments/EquipmentsSlice";
import Users from "../features/Settings/Users/UsersSlice";
import Activities from "../features/Settings/Activities/ActivitiesSlice";
import SecurityAlert from "../features/Settings/SecurityAlerts/SecurityAlertSlice";
import SettingClients from "../features/Settings/Client/ClientSlice";
import WorkItemType from "../features/Settings/WorkItemType/WorkItemTypeSlice";
import SalesQuote from "../features/CreateQuote/SalesQuotesSlice";
import GlobalSpinner from "../features/CommonComponents/GlobalSpinner/GlobalSpinnerSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    authentication: authentication,
    Suppliers: Suppliers,
    Suppliers: Categories,
    Suppliers: Materials,
    SubContractors: SubContractors,
    Equipments: Equipments,
    Users: Users,
    Activities: Activities,
    SecurityAlert: SecurityAlert,
    Clients: SettingClients,
    WorkItemType: WorkItemType,
    GlobalSpinner: GlobalSpinner,
    SalesQuote: SalesQuote,
  },
});
