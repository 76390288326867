import React, { useState, Suspense, useContext } from "react";
import { AuditOutlined, MenuOutlined, ToolOutlined } from "@ant-design/icons";
import { Layout, Menu, Row, Col, Select, Space, Dropdown, Avatar } from "antd";
import logo from "../logo.png";
import logo1 from "../logo1.png";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import routes from "./routes";
import { Context } from "../components/Wrapper";
import { FormattedMessage } from "react-intl";
const { Header, Content, Footer, Sider } = Layout;
function ProtectedRoutes() {
  const context = useContext(Context);
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <Layout>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="lg"
            defaultCollapsed={true}
            collapsedWidth="60"
            width={280}
          >
            <div className="new-logo">
              <img
                src={logo}
                className="img-fluid after-login-logo normal-logo"
                alt=""
              />
              <img
                src={logo1}
                className="img-fluid after-login-logo collapsed-logo"
                alt=""
              />
            </div>
            <div style={{ height: "50px" }}></div>
            <Menu theme="light" mode="inline" defaultSelectedKeys={["7"]}>
              <Menu.Item key="7" icon={<AuditOutlined />}>
                <FormattedMessage id="Quotes.Heading" />
                <Link to="/quotes" />
              </Menu.Item>
              <Menu.Item key="3" icon={<ToolOutlined />}>
                <FormattedMessage id="Settings.Equipments.Settings" />
                <Link to="/settings" />
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout
            className="site-layout"
            style={{
              overflow: "auto",
              minHeight: "100vh",
              width: "100%",
              paddingBottom: "56px",
              left: 0,
            }}
          >
            <Header
              className="site-layout-background"
              style={{
                padding: "0 20px 0 0",
                borderBottom: "solid 1px #eee",
              }}
            >
              <Row>
                <Col flex="auto" style={{ paddingLeft: "8px" }}>
                  {React.createElement(
                    collapsed ? MenuOutlined : MenuOutlined,
                    {
                      className: "trigger",
                      onClick: () => setCollapsed(!collapsed),
                    }
                  )}
                </Col>
                <Col flex="100px" className="langselect">
                  <Select
                    value={context.locale}
                    onChange={context.selectLang}
                    variant="borderless"
                  >
                    <option value="en-US">English</option>
                    <option value="fr-FR">Français</option>
                    {/* <option value="es-ES">Spanish</option> */}
                  </Select>
                </Col>
                <Col flex="100px">
                  <Space align="end">
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    &nbsp;
                    {
                      <a
                        onClick={() => {
                          localStorage.clear();
                          window.location.reload(true);
                        }}
                      >
                        Logout
                      </a>
                    }
                  </Space>
                </Col>
              </Row>
            </Header>
            <Content
              className="site-layout-background content-padding"
              style={{
                margin: "0",
                minHeight: 280,
                textAlign: "justify",
              }}
            >
              <Routes>
                {routes.map(({ component: Component, path, exact }, index) => (
                  <Route
                    path={`/${path}`}
                    key={index}
                    exact={exact}
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <Component />
                      </Suspense>
                    }
                  >
                    {/* <GlobalSpinner
                          appContainer={<Component />}
                        ></GlobalSpinner> */}
                    {/* <div className={`fg ${isActiveMenu ? "shown" : ""}`}>
                          <SideMenu />
                        </div> */}
                  </Route>
                ))}
              </Routes>
            </Content>
            <Footer
              style={{
                borderTop: "1px solid #e8e8e8",
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              <FormattedMessage id="Routes.footer" />
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}

export default ProtectedRoutes;
