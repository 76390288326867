import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
//import { login, loginSuccess } from "./authenticationSlice";
import { loginAPI } from "./authenticateAPI";
import logo from "../../logo.png";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";
function LoginPage() {
  const loader = useSelector((state) => state.authentication?.loader);
  const intl = useIntl();
  const dispatch = useDispatch();

  const HandleSubmit = (values) => {
    debugger;
    dispatch(loginAPI(values));
  };
  return (
    <>
      <div className="ht-100v auth d-flex">
        <Row style={{ width: "100%" }}>
          <Col xs={24} md={14} lg={18} className="new-md-none">
            <div className="login-new-bg"></div>
          </Col>
          <Col xs={24} md={10} lg={6}>
            <img src={logo} className="img-fluid" alt="" />
            <div className="form-wrapper">
              <div className="card-block">
                <div className="bg-light">
                  <div className="">
                    <div className="my-5">
                      <h1 className="tx-dark mg-b-5 tx-left">
                        <FormattedMessage id="Login.SignIn" />
                      </h1>
                    </div>
                    <Form
                      name="normal_login"
                      className="form"
                      layout="vertical"
                      onFinish={HandleSubmit}
                    >
                      <Form.Item
                        name="userName"
                        label={<FormattedMessage id="Login.UserName" />}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Username!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder={intl.formatMessage({
                            id: "Login.UserName",
                          })}
                          autoComplete="userName"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label={<FormattedMessage id="Login.Password" />}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder={intl.formatMessage({
                            id: "Login.PasswordPlaceholder",
                          })}
                          autoComplete="current-password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>
                            <FormattedMessage id="Login.Rememberme" />
                          </Checkbox>
                        </Form.Item>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          block
                          loading={loader}
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          size="large"
                        >
                          <FormattedMessage id="Login.Signin" />
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LoginPage;
