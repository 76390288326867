import { createSlice } from "@reduxjs/toolkit";
//import jwtDecode from "jwt-decode";
import { jwtDecode } from "jwt-decode";

const initialState = {
  isAuthenticated: false,
  loader: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login(state) {
      state.loader = true;
    },
    loginSuccess(state, action) {
      state.loader = false;
      state.isAuthenticated = !!action.payload.token;
      state.token = JSON.stringify(action.payload.token);
      localStorage.setItem("isAuthenticated", state.isAuthenticated);
      localStorage.setItem("token", state.token);
      localStorage.setItem("token_bearer", action.payload.token);
      localStorage.setItem("token_type", action.payload.token_type);
      const decoded = jwtDecode(action.payload.token);
      if (decoded) {
        state.user = JSON.stringify(decoded);
        localStorage.setItem("user", JSON.stringify(decoded));
        localStorage.setItem("userName", decoded.displayname);
        localStorage.setItem("userId", decoded.employeeid);
      }
      if (action.payload.roles) {
        state.roles = action.payload.roles;
        localStorage.setItem("roles", action.payload.roles);
      }
    },
    loginFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
    },
    loginSessionAvailable(state) {
      let token = localStorage.getItem("token");
      let token_type = localStorage.getItem("token_type");
      let token_bearer = localStorage.getItem("token_bearer");
      if (token && token_type && token_bearer) {
        const decoded = jwtDecode(token);
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        if (date === undefined || !(date.valueOf() > new Date().valueOf())) {
          state.isAuthenticated = false;
        } else {
          state.isAuthenticated = true;
        }
      } else {
        state.isAuthenticated = false;
      }
    },
  },
});

export const { login, loginSuccess, loginFailure, loginSessionAvailable } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
