import axios from "axios";
// import constants from "common/utils/constants";
import constants from "../common/utils/constants";
import { useDebugValue } from "react";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = constants.HOST_URL;

axiosClient.defaults.headers = constants.headers;

// To share cookies to cross site domain, change to true.
axiosClient.defaults.withCredentials = false;

axiosClient.interceptors.request.use(function (config) {
  var token = localStorage.getItem("token_bearer");
  var token_type = localStorage.getItem("token_type");
  var authHeader = `${token_type} ${token}`;
  config.headers.Authorization = authHeader;
  return config;
});

export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then((response) => response);
}
