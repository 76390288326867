//import { all, call, put, takeLatest } from "redux-saga/effects";
import { login, loginSuccess, loginFailure } from "./authenticationSlice";
//import { postRequest } from "app/axiosClient";
//import { message } from "antd";
import { postRequest } from "../../app/axiosClient";
import {
  showLoader,
  hideLoader,
} from "../CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import { showResultPopupMessage } from "../CommonComponents/ResultPopup/ResultPopupSlice";
import { FormattedMessage } from "react-intl";
import Password from "antd/es/input/Password";
// function* loginAPI(action) {
//   debugger;
//   message.destroy();
//   try {
//     const response = yield call(() =>
//       postRequest("api/Accounts/Login", action.payload)
//     );
//     yield put(loginSuccess(response.data));
//     message.success("Connexion réussie");
//   } catch (e) {
//     yield put(loginFailure());
//     message.error("Nom d'utilisateur ou mot de passe invalide", 0);
//   }
// }

// export default function* rootSaga() {
//   yield all([takeLatest(login, loginAPI)]);
// }

export const loginAPI = (values) => (dispatch) => {
  try {
    dispatch(showLoader());

    postRequest("api/Accounts/Login", values)
      .then((res) => {
        console.log("res.data", res.data);
        localStorage.setItem("userData", JSON.stringify(res?.data));
        dispatch(loginSuccess(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        debugger;
        dispatch(
          showResultPopupMessage({
            status: "error",
            title: <FormattedMessage id="Login Failed" />,
            subTitle: <FormattedMessage id=".Unable to login" />,
          })
        );
      });
  } catch {
    debugger;
    dispatch(
      showResultPopupMessage({
        status: "error",
        title: <FormattedMessage id="Login Failed" />,
        subTitle: <FormattedMessage id=".Unable to login" />,
      })
    );
  }
};
