import { createSlice } from "@reduxjs/toolkit";

export const ResultPopupSlice = createSlice({
  name: "ResultPopup",
  initialState: {
    isModalOpen: false,
    status: [],
    title: [],
    subTitle: [],
    showBackButton: false,
    backButtonRedirectPath: "",
    backButtonName: "",
  },

  reducers: {
    showOrHideResultPopup(state, action) {
      debugger;
      if (state) {
        state.isModalOpen = action.payload;
        state.status = "";
        state.title = "";
        state.subTitle = "";
        state.showBackButton = false;
        state.backButtonRedirectPath = "";
        state.backButtonName = "";
      }
    },

    showResultPopupMessage: (state, action) => {
      debugger;
      if (state) {
        state.isModalOpen = true;
        state.status = action.payload.status;
        state.title = action.payload.title;
        state.subTitle = action.payload.subTitle;
        state.showBackButton = action.payload.showBackButton ?? false;
        state.backButtonRedirectPath = action.payload.backButtonRedirectPath;
        state.backButtonName = action.payload.backButtonName;
      }
    },
  },
});

export const { showResultPopupMessage, showOrHideResultPopup } =
  ResultPopupSlice.actions;

export default ResultPopupSlice.reducer;
